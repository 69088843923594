import React from 'react';
import PropTypes from 'prop-types';

import { withSegmentContext } from 'components/core/library/segment';
import FooterColumn from './FooterColumn';

const links = [
	{ href: 'https://www.selfstorage.com/', text: 'SelfStorage.com' },
	{ href: 'https://www.storage.com/', text: 'Storage.com' }
];

const enhance = withSegmentContext;

export function AffiliatesLinks({ className }) {
	return (
		<FooterColumn
			className={className}
			links={links}
			title="SpareFoot Affiliates"
		/>
	);
}

AffiliatesLinks.propTypes = {
	className: PropTypes.string
};

export default enhance(AffiliatesLinks);
