import React from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Text } from 'components/core/Typography';
import { Row, Column, Group } from 'components/core/Page';
import { Link } from 'components/core/Link';
import { Logo } from 'components/svg';
import { ClickToShowNumberButton } from 'components/contact/ClickToShowNumberButton';
import { withSegmentContext } from 'components/core/library/segment';

import AboutSpareFoot from './AboutSpareFoot';
import ForOwners from './ForOwners';
import ProductsByType from './ProductsByType';
import AffiliatesLinks from './AffiliatesLinks';
import SocialIcons from './SocialIcons';

const enhance = withSegmentContext;
export function LowerContent({ year, getTollFreePhoneNumber }) {
	return (
		<Group className="lower-content">
			<div className="logo-wrapper">
				<Link
					href="/"
					className="logo-link"
					hideBorder
					segmentLabel="logo"
				>
					<Logo />
				</Link>
			</div>
			<Row>
				<Column xlarge={5} medium={12}>
					<Paragraph color="white" weight="medium">
						SpareFoot is the largest marketplace for finding and
						reserving a self-storage unit. For more than a decade,
						SpareFoot has helped consumers get the best deals on
						self-storage by making it easy to find a storage unit at
						the lowest price.
					</Paragraph>
					<Paragraph color="white" weight="medium">
						With more than 20,000 storage facilities in all 50
						states, SpareFoot offers the nation's largest selection
						of storage options in one place. Whether you are moving,
						starting a business, or need a place to store your RV,
						boat or car, SpareFoot helps you find the perfect
						storage space to fit your needs. Reserve online or call
						to book a storage unit today!
					</Paragraph>
				</Column>
				<Column medium={4} xlarge={2}>
					<AboutSpareFoot
						className="about-sparefoot"
						segmentPrefix="about sparefoot"
					/>
				</Column>
				<Column medium={4} xlarge={2}>
					<Group>
						<ProductsByType
							className="products-by-type"
							segmentPrefix="products by type"
						/>
					</Group>
					<Group>
						<AffiliatesLinks segmentPrefix="affiliates Links" />
					</Group>
				</Column>
				<Column medium={4} xlarge={3}>
					<Group>
						<ForOwners segmentPrefix="for owners" />
					</Group>

					<Group>
						<Paragraph color="gray" size="medium">
							Need help?
						</Paragraph>

						<Link
							href="https://about.sparefoot.com/contact-us"
							color="white"
							segmentLabel="Contact Us"
						>
							<Text color="white" size="small">
								Contact Us
							</Text>
						</Link>
					</Group>

					<Group>
						<ClickToShowNumberButton
							className="white"
							outline
							size="standard"
							fetchPhoneNumber={getTollFreePhoneNumber}
							showAsLink
							segmentLabel="click to show number"
						/>
					</Group>
					<SocialIcons segmentPrefix="social links" />
					<Paragraph color="gray" size="small">
						&copy;{year} SpareFoot
					</Paragraph>
				</Column>
			</Row>
		</Group>
	);
}

LowerContent.propTypes = {
	year: PropTypes.number,
	getTollFreePhoneNumber: PropTypes.func
};

export default enhance(LowerContent);
