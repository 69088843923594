import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import classnames from 'classnames';

import './coreImage.scss';

/**
 * Renders an image component.
 *
 * **Base CSS Class:** `ph-image`
 */

export default class CoreImage extends PureComponent {
	static propTypes = {
		src: PropTypes.string.isRequired,
		alt: PropTypes.string,
		role: PropTypes.string,
		flexImage: PropTypes.bool,
		titleIcon: PropTypes.bool,
		className: PropTypes.string
	};

	static defaultProps = {
		flexImage: false,
		titleIcon: false
	};

	render() {
		if (this.props.flexImage) {
			return (
				<div
					className={classnames('ph-image', this.props.className, {
						'title-icon': this.props.titleIcon
					})}
					style={{
						backgroundImage: `url(${this.props.src})`,
						backgroundPosition: 'center',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat'
					}}
				/>
			);
		}

		return (
			<img
				{...omit(this.props, ['flexImage', 'titleIcon'])}
				role={this.props.role}
				alt={this.props.alt}
				className={classnames('ph-image', this.props.className, {
					'title-icon': this.props.titleIcon
				})}
			/>
		);
	}
}
