import React from 'react';

import { withSegmentContext } from 'components/core/library/segment';
import FooterColumn from './FooterColumn';

const ABOUT_URL = 'https://about.sparefoot.com';
const links = [
	{ href: `${ABOUT_URL}`, text: 'About' },
	{ href: `${ABOUT_URL}/jobs`, text: 'Jobs' },
	{ href: '/self-storage/blog/', text: 'Blog' },
	{ href: 'https://www.sparefoot.com/self-storage/news/', text: 'The Storage Beat' },
	{ href: '/storage.html', text: 'Storage near me' },
	{ href: '/moving/', text: 'Moving guides' },
	{ href: '/legal/consumer.html', text: 'Terms of use', rel: 'nofollow' },
	{ href: '/legal/privacy.html', text: 'Privacy policy' }
];

const enhance = withSegmentContext;

export function AboutSpareFoot(props) {
	return (
		<FooterColumn
			{...props}
			links={links}
			title="About SpareFoot"
		/>
	);
}

export default enhance(AboutSpareFoot);
