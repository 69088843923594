import React, { Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Paragraph, Text } from 'components/core/Typography';
import { Link } from 'components/core/Link';

export default function FooterColumn({ className, title, links }) {
	return (
		<div className={classnames('footer-col', className)}>
			<Paragraph size="small" color="gray">
				{title}
			</Paragraph>
			{Children.toArray(links.map(({ href, text, rel }) => (
				<Link href={href} rel={rel} color="white" segmentLabel={text}>
					<Text color="white" size="small">
						{text}
					</Text>
				</Link>
			)))}
		</div>
	);
}

FooterColumn.propTypes = {
	className: PropTypes.string,
	links: PropTypes.arrayOf(PropTypes.object).isRequired,
	title: PropTypes.string.isRequired
};
