/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { keys, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import favicon from 'assets/images/favicon.ico';
import logo from 'assets/images/logo.svg';

export default function PageMetadata({
	title, meta, og, links, structuredData, noindex
}) {
	return (
		<Helmet>
			<link rel="icon" href={favicon} />
			<meta
				property="og:site_name"
				content="Sparefoot"
			/>
			<title key="title">{title}</title>
			<meta key="og:title" property="og:title" content={title} />
			<meta
				property="og:image"
				content={logo}
			/>
			{keys(meta).map((key) => (
				<meta name={key} content={meta[key]} key={key} />
			))}
			{keys(og).map((key) => (
				<meta property={`og:${key}`} content={og[key]} key={key} />
			))}
			{links.map((link, i) => (
				<link {...link} key={i} />
			))}
			{!isEmpty(structuredData) &&
				<script type="application/ld+json">
					{JSON.stringify({
						...structuredData,
						description: decodeURIComponent(structuredData.description || '')
					})}
				</script>
			}
			{!!noindex &&
				<meta name="robots" content="noindex, nofollow" />
			}
		</Helmet>
	);
}

PageMetadata.propTypes = {
	title: PropTypes.string.isRequired,
	meta: PropTypes.object,
	og: PropTypes.object,
	links: PropTypes.arrayOf(PropTypes.object),
	structuredData: PropTypes.object,
	noindex: PropTypes.bool
};

PageMetadata.defaultProps = {
	meta: {},
	og: {},
	links: [],
	noindex: false
};
